<template>
  <v-container>
 <v-row>
      <v-col md="8">
        <v-card
      outlined
      class="pa-5 d-flex justify-space-between"
    >
      <div class="">
        <div class="mb-5">
          <h2 class="headline text-capitalize mb-2">
            {{ onSiteConsultationDetails.title }}
          </h2>
          <div class="body">Status: {{ onSiteConsultationDetails.status }}</div>
          <div class="body">Approved Date: {{ onSiteConsultationDetails.approvedDate ? formatDate(onSiteConsultationDetails.approvedDate, 'Do MMM YYYY hh:mm A') : '-'}}</div>
          <div class="body">
            Preferrerd Date:
            {{ formatDate(onSiteConsultationDetails.preferredDate, "Do MMM YYYY hh:mm A") }}
          </div>
        </div>

        <div class="body">Area: {{ onSiteConsultationDetails.area }}</div>
        <div class="body">
          Inquiry Type: {{ onSiteConsultationDetails.inquiryType }}
        </div>
        <div class="body">
          Description: {{ onSiteConsultationDetails.description }}
        </div>

        <div class="body">Remarks: {{ onSiteConsultationDetails.remarks }}</div>
      </div>
       <div class="action-btns">
        <v-btn
          @click="approveOnSiteDialog = true"
          v-if="onSiteConsultationDetails.status == 'Pending'"
          color="primary"
        >
          <v-icon class="pr-3">mdi-check</v-icon> Approve</v-btn
        >
        <v-btn
          @click="markCompleted"
          color="primary"
          v-if="onSiteConsultationDetails.status == 'Approved'"
        >
          <v-icon class="pr-3">mdi-check</v-icon>
          Visited</v-btn
        >
      </div>
    </v-card>
      </v-col>
      <v-col md="4">
        <v-card  outlined
      class="pa-5 mx-auto">
      <h2 class="headline text-capitalize mb-2">
           Customer Details
          </h2>
            <div>
               Name: {{onSiteConsultationDetails.customer.name}}
            </div>
            <div>
                Email: {{onSiteConsultationDetails.customer.email}}
            </div>
            <div>
                Phone: {{onSiteConsultationDetails.customer.phone}}
            </div>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="approveOnSiteDialog" max-width="600">
      <v-card class="pa-5">
        <v-card-title class="text-h5">
          Approve On Site Consultation
        </v-card-title>
        <v-select
          :items="expertList"
          item-text="name"
          item-value="id"
          filled
          label="Choose Expert To Assign"
          v-model="approveOnSiteConsultationDetails.expertId"
        ></v-select>

        <v-datetime-picker label="Select Datetime" v-model="approveOnSiteConsultationDetails.approvedDate">
          <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
           </v-datetime-picker>


        <!-- <v-menu
          v-model="datePickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="approveOnSiteConsultationDetails.approvedDate"
              label="Approved Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="approveOnSiteConsultationDetails.approvedDate"
            @input="datePickerMenu = false"
          ></v-date-picker>
        </v-menu> -->
        <v-text-field
          label="Remarks"
          v-model="approveOnSiteConsultationDetails.remarks"
        ></v-text-field>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="approveOnSiteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn color="green darken-1" text @click="approve"> Approve </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      approveOnSiteDialog: false,
      datePickerMenu: false,
      expertList: [],
      onSiteConsultationDetails: {},
      approveOnSiteConsultationDetails: {
        approvedDate: "",
        remarks: "",
        id: this.$route.params.id,
      },
    };
  },
  methods: {
    getOnSiteConsultationById() {
      this.$General
        .getOnSiteConsultationById(this.$route.params.id)
        .then((data) => {
          this.onSiteConsultationDetails = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getExpertList() {
      this.$Expert
        .getExpertList()
        .then((data) => {
          this.expertList = data.data.data.list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    approve() {
      this.$General
        .approveOnSiteConsultation(this.approveOnSiteConsultationDetails)
        .then((data) => {
          this.$toastr.success("Onsite Consultation Approved Successfully");
          this.approveOnSiteDialog = false;
          this.getOnSiteConsultationById();
        })
        .catch((error) => {
          if (error.response) {
            this.$toastr.error(error.response.data.message);
          } else {
            this.$toastr.error("Something Went Wrong");
          }
        });
    },
    markCompleted() {
      this.$General
        .onSiteConsultationCompleted(this.$route.params.id)
        .then((data) => {
          this.$toastr.success("This item has been marked complete");
          this.getOnSiteConsultationById();
        })
        .catch((error) => {
          if (error.response) {
            this.$toastr.error(error.response.data.message);
          } else {
            this.$toastr.error("Something Went Wrong");
          }
        });
    },
  },

  mounted() {
    this.getOnSiteConsultationById();
    this.getExpertList();
  },
};
</script>


approvedDate: (...)
area: (...)
customer: (...)
description: (...)
id: (...)
inquiryType: (...)
link: (...)
preferredDate: (...)
remarks: (...)
status: (...)
title: (...)


